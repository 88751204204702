
function addClipboardSupport(selector) {

  if(typeof(Clipboard) === "undefined") {
    return;
  }

  initAutoCopyElements()
  var clipboard = new Clipboard(selector);
  $('[data-toggle="tooltip"]').tooltip();

  clipboard.on('success', function(e) {

    let copySuccess = e.trigger.getAttribute('data-copy-success') || "Copied!";
    let initialText = e.trigger.getAttribute('data-initial-tooltip');

    e.clearSelection();

    updateTooltip(e.trigger, copySuccess);
    setUpListener(e.trigger, initialText);
  });

  /**
   * This is not working correctly.
   * The goal is to have it restore the original text (eg: "Click to copy...")
   * after the mouse leaves the element. All the events seem to fire correctly,
   * but it is not updating. It does remove the "Copied" value, so the end
   * result is "acceptable" right now...but...it's not ideal.
   */
  function setUpListener(el, restoredText) {
    el.addEventListener('mouseleave', function(e) {

      updateTooltip(el, restoredText);
    });
  }

  function updateTooltip(elem, msg) {
    $(elem).attr('data-original-title', msg).tooltip('show');
  }
}


function initAutoCopyElements() {

  let els = document.querySelectorAll('.auto-copy');

  for (var i=0; i < els.length; i++) {
    els[i].setAttribute('data-clipboard-text', els[i].innerHTML);
  }
}
