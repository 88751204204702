function rgb2hex(rgb) {

    //isolate numbers
    var hex = rgb.replace("rgb(", "").replace(")", "")

    // array of 3 numbers
    .split(", ")

    //convert each number to Base16 aka Hex
    .map(function(num) {
      return Number(num).toString(16)
    })

    //pad single digits with a leading 0
    .map(function(str) {
      return str.length == 1 ? "0" + str : str
    })

    //array -> string
    .join("");

    return "#" + hex;
  }
