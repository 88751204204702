function addScrollPoint(element, options) {
  if(!(element && options.offset && options.downClass)) {
    let err = new Error('Missing options for adding "scroll point." Received: ' + JSON.stringify(options));
    throw err;
  }

  // allows element other than `el` to be updated based on `el`'s scroll position
  let updateEl = options.updateContainer && $(options.updateContainer) || $(element);

  return new Waypoint({
    element: element,
    handler: function(direction) {

      if (direction === 'down') { $(updateEl).addClass(options.downClass); }
      else                      { $(updateEl).removeClass(options.downClass); }
    },
    offset: options.offset
  })
}


function autoScrollPoint(spSelector) {
  var attrMapping = {
    'downClass':       'data-scroll-down-class',
    'updateContainer': 'data-update-container',
    'offset':          'data-offset'
  };

  function warning(option, attr) {
    console.log('Warning: "' + option + '" option not set on auto "scroll point." Set "' + attr + ' "attributte on element.')
  }

  let els = $(spSelector);

  for(let i=0; i<els.length; i++) {
    let el          = els[i];
    // hack. some interaction w/ minifying is preventing `Object.keys` from working
    // correctly.
    // TODO: investigate...
    let attrOptions = ['downClass', 'updateContainer', 'offset'];
    let options     = {};

    for(let i=0; i < attrOptions.length; i++ ) {

      let optionName   = attrOptions[i];
      let htmlAttrName = attrMapping[optionName];

      if(el.attributes[htmlAttrName]) {
        options[optionName] = el.attributes[htmlAttrName].value;
      }
      else {
        // warning(optionName, htmlAttrName); // debug
      }
    }

    addScrollPoint(el, options);
  }
};
