function sidebar(title) {
  let sbContainer = document.createElement('div');
  let sbEl        = document.createElement('ul');


  sbContainer.classList.add('sidebar-container');
  sbEl.classList.add('nav');
  sbEl.classList.add('nav-pills');
  sbEl.classList.add('flex-column');

  if(title) {
    sbContainer.appendChild(topQuickJumpEl(title));
  }

  sbContainer.addNavItem = function (item) {
    sbEl.appendChild(item);
    return sbContainer;
  }

  sbContainer.appendChild(sbEl);
  return sbContainer;
}


function topQuickJumpEl(title) {
  let titleEl = document.createElement('h5');
  let quickJumpA  = document.createElement('a');
  let quickJumpEl = document.createElement('img');


  titleEl.classList.add('title');

  quickJumpA.setAttribute('href', '#');
  quickJumpA.textContent = title;

  quickJumpEl.classList.add('iconic');
  quickJumpEl.classList.add('iconic-sm');
  quickJumpEl.setAttribute('data-direction', 'top');
  quickJumpEl.setAttribute('data-src', 'https://branding.gn-as.com/imgs/icons/iconic/smart/arrow.svg');
  quickJumpEl.setAttribute('alt', 'arrow');


  quickJumpA.appendChild(quickJumpEl);

  titleEl.appendChild(quickJumpA)
  return titleEl;
}


function sidebarElement(linkText, linkRef) {
  let li = document.createElement('li');
  let a  = document.createElement('a');

  li.classList.add('nav-link');

  a.setAttribute('href', linkRef);
  a.textContent = linkText;

  li.appendChild(a);

  return li;
}


function getAnchorFor(el) {
  let anchors = el.querySelectorAll('a.for-sidebar');

  if(anchors.length === 0) {
    // no anchor exists...create one, append it and return it
    let a    = document.createElement('a');
    let elId = el.id;

    if(!elId) {
      elId = el.textContent.toLowerCase().replace(/\s+/g, '').replace(/['"!.?:]/g, '')
    }

    el.id = elId;
    el.classList.add('anchored');

    a.classList.add('anchor');
    a.href = '#' + elId;
    a.setAttribute('data-spy', 'scroll'); //scroll-spy support
    a.setAttribute('data-target', '#' + elId);
    a.setAttribute('aria-hidden', 'true');

    return a;
  }
  else if (anchors.length === 1) {
    // a manual sidebar anchor has been created
    return anchors[0];
  }
  else {
    let err = new Error('Multiple anchor tags found, but none specified to be for the sidebar');
    throw err;
  }
}


function autoSidebar(selector, options) {
  options = options || {};
  let sidebarEl   = sidebar(options.title);
  let matchingEls = document.querySelectorAll(selector);
  let linkTextAttribute = options['link-text-attr'] || 'data-sidebar-text';


  for(let i=0; i < matchingEls.length; i++) {
    let shouldIgnoreAttr = matchingEls[i].getAttribute('data-sidebar-ignore');

    if(!(shouldIgnoreAttr || shouldIgnoreAttr === 'true')) {
      let el = matchingEls[i];
      let linkText = el.getAttribute(linkTextAttribute) || el.textContent;
      let anchor   = getAnchorFor(el);

      el.appendChild(anchor);

      sidebarEl.addNavItem(sidebarElement(linkText, anchor.getAttribute('data-target')));
    }
  };

  return sidebarEl;
};
